import React, {useState, useEffect} from "react";
import {
    Backdrop,
    Box,
    Container,
    Stack,
    Dialog,
    DialogActions,
    DialogContent,
    Slide,
    Divider,
    ListItemButton,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import {LocalizationProvider} from "@mui/x-date-pickers";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import EditIcon from "@mui/icons-material/Edit";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {motion, useAnimationControls, useScroll} from "framer-motion";
import {useDispatch} from "react-redux";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SwipeRightIcon from "@mui/icons-material/SwipeRight";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import bg from "../../../assets/background/bg3000.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import profileService from "../../../services/profile.service";
import {useTranslation, i18next} from "react-i18next";
import {GraduateInstruction} from "../../ProfileValidation";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const StudentDashboard = (props) => {
    const {t, i18n} = useTranslation();
    let navigate = useNavigate();
    const [userInfo, setUserInfo] = useState([]);
    const [currentPlaceName, setCurrentPlaceName] = useState(null);
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const nonBreakingSpace = String.fromCharCode(160);
    const [registered, setRegistered] = useState(false);
    const [popUpState, setPopUpState] = useState(false);

    useEffect(() => {
        getProfileInfo();
    }, []);

    const getProfileInfo = () => {
        profileService.getProfileInfo(currentUser.userId).then(
            (response) => {
                setUserInfo(response.data);
                // setPresentCountry(response.data.presentCountry);
                let presentPlaceName = response.data.presentPlaceName;
                let presentGeogName = response.data.presentGeogName;
                let presentDzongkhagName = response.data.presentDzongkhagName;
                let presendCountry = response.data.presentCountry;
                setCurrentPlaceName(presentPlaceName);
                let address = "";
                if (presendCountry === "Bhutan") {
                    address =
                        presentPlaceName +
                        ", " +
                        presentGeogName +
                        ", " +
                        presentDzongkhagName +
                        " " +
                        presendCountry;
                } else {
                    address = presentPlaceName + ", " + presendCountry;
                }

                let forceUpdate = localStorage.getItem("forceUpdate");
                if (forceUpdate !== "SKIPPED") {
                    setPopUpState(true);
                }
            },
            (error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
            }
        );
    };


    return (
        <Box
            zIndex={100}
            bgcolor={"transparent"}
            sx={{
                backgroundImage: `url(${bg})`,
                backgroundRepeat: "repeat",
                backgroundSize: "300px",
            }}
        >
            <Container maxWidth="lg">
                <Box
                    sx={{
                        width: "100%",
                        color: "gray",
                        // transform: "skewY(-3deg)",
                        // background: "linear-gradient(to right bottom, #e6b400, skyblue)",
                        // top: 45,
                        py: 5,
                        height: "100vh",
                    }}
                >
                    <Stack
                        direction={{xs: "column", sm: "row"}}
                        py={1}
                        spacing={1}
                        gap={1}
                    >
                        {/*col 1*/}
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                cursor: "pointer",
                                gap: 2,
                                "&:hover": {
                                    "& .arrow": {
                                        transform: "scale(2)",
                                    },
                                },
                                justifyContent: "space-between",
                            }}
                            onClick={() => {
                                navigate("/authenticatedViewWrapper/nsRegistration");
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <PersonAddIcon sx={{width: 40, height: 40}}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">
                                                {t("National Service Registration")}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography>
                                                {t(
                                                    "National Serivce (NS) Registration is a process where you will be undergoing mandatory training of 1 year."
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                            <Box className={"arrow"} display={"flex"} alignItems={"center"}>
                                <KeyboardDoubleArrowRightIcon/>
                            </Box>
                        </Box>
                        {/*col2*/}
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                cursor: "pointer",
                                gap: 2,
                                "&:hover": {
                                    "& .arrow": {
                                        transform: "scale(2)",
                                    },
                                },
                                justifyContent: "space-between",
                            }}
                            onClick={() => {
                                navigate(
                                    "/authenticatedViewWrapper/myEarlyEnlistmentApplications"
                                );
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <GroupAddIcon sx={{width: 40, height: 40}}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">
                                                {t("Early Enlistment")}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography>
                                                {t(
                                                    "Below 18 years but above 16.5 years on the completion of class XII or its equivalent, you may volunteer for early registration with the consent of the parents or legal guardian."
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                            <Box className={"arrow"} display={"flex"} alignItems={"center"}>
                                <KeyboardDoubleArrowRightIcon/>
                            </Box>
                        </Box>
                    </Stack>

                    <Stack
                        direction={{xs: "column", sm: "row"}}
                        py={1}
                        spacing={1}
                        gap={1}
                    >
                        {/*col 1*/}
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                cursor: "pointer",
                                gap: 2,
                                "&:hover": {
                                    "& .arrow": {
                                        transform: "scale(2)",
                                    },
                                },
                                justifyContent: "space-between",
                            }}
                            onClick={() => {
                                navigate("/authenticatedViewWrapper/myDefermentApplications");
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <EventRepeatIcon sx={{width: 40, height: 40}}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">{t("Deferment")}</Typography>
                                        }
                                        secondary={
                                            <Typography align="justify">
                                                {t(
                                                    "If you are eligible for deferment, your Gyalsung training will be deferred for one year, and you will need to register for the subsequent Gyalsung training."
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                            <Box className={"arrow"} display={"flex"} alignItems={"center"}>
                                <KeyboardDoubleArrowRightIcon/>
                            </Box>
                        </Box>

                        {/*col2*/}
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                cursor: "pointer",
                                gap: 2,
                                "&:hover": {
                                    "& .arrow": {
                                        transform: "scale(2)",
                                    },
                                },
                                justifyContent: "space-between",
                            }}
                            onClick={() => {
                                navigate("/authenticatedViewWrapper/myExemptionApplications");
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <PersonAddDisabledIcon sx={{width: 40, height: 40}}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">{t("Exemption")}</Typography>
                                        }
                                        secondary={
                                            <Typography>
                                                {t(
                                                    "If you are eligible for exemption, you will be exempt from the National Service obligation and no longer required to undergo training."
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                            <Box className={"arrow"} display={"flex"} alignItems={"center"}>
                                <KeyboardDoubleArrowRightIcon/>
                            </Box>
                        </Box>
                    </Stack>

                    <Stack
                        direction={{xs: "column", sm: "row"}}
                        py={1}
                        spacing={1}
                        gap={1}
                    >
                        {/*col 1*/}
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                cursor: "pointer",
                                gap: 2,
                                "&:hover": {
                                    "& .arrow": {
                                        transform: "scale(2)",
                                    },
                                },
                                justifyContent: "space-between",
                            }}
                            onClick={() => {
                                navigate("/authenticatedViewWrapper/myMedicalDashboard");
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <LocalHospitalIcon sx={{width: 40, height: 40}}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">
                                                {t("Medical Screening")}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography>
                                                {t(
                                                    "You are required to book an appointment for a pre-enlistment medical examination."
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                            <Box className={"arrow"} display={"flex"} alignItems={"center"}>
                                <KeyboardDoubleArrowRightIcon/>
                            </Box>
                        </Box>

                        {/*col2*/}
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                cursor: "pointer",
                                gap: 2,
                                "&:hover": {
                                    "& .arrow": {
                                        transform: "scale(2)",
                                    },
                                },
                                justifyContent: "space-between",
                            }}
                            onClick={() => {
                                navigate("/authenticatedViewWrapper/gyalsupProfile");
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <PersonIcon sx={{width: 40, height: 40}}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">{t("My Profile")}</Typography>
                                        }
                                        secondary={<Typography>{t("User Profile")}</Typography>}
                                    />
                                </ListItem>
                            </List>
                            <Box className={"arrow"} display={"flex"} alignItems={"center"}>
                                <KeyboardDoubleArrowRightIcon/>
                            </Box>
                        </Box>
                    </Stack>

                    <Stack
                        direction={{xs: "column", sm: "row"}}
                        py={1}
                        spacing={1}
                        gap={1}
                    >
                        {/*col2*/}
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                cursor: "pointer",
                                gap: 2,
                                "&:hover": {
                                    "& .arrow": {
                                        transform: "scale(2)",
                                    },
                                },
                                justifyContent: "space-between",
                            }}
                            onClick={() => {
                                navigate("/authenticatedViewWrapper/settingDashboard");
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <ManageAccountsIcon sx={{width: 40, height: 40}}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">{t("Setting")}</Typography>
                                        }
                                        secondary={
                                            <Typography>
                                                {t(
                                                    "Change Password, Email, Mobile number and Username."
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                            <Box className={"arrow"} display={"flex"} alignItems={"center"}>
                                <KeyboardDoubleArrowRightIcon/>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                cursor: "pointer",
                                gap: 2,
                                "&:hover": {
                                    "& .arrow": {
                                        transform: "scale(2)",
                                    },
                                },
                                justifyContent: "space-between",
                            }}
                        ></Box>
                    </Stack>
                </Box>
            </Container>

            <GraduateInstruction userInfo={userInfo}  popUpState={popUpState} setPopUpState={setPopUpState}/>
        </Box>
    );
};

export default StudentDashboard;
