import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = process.env.REACT_APP_BASE_URL + "api/enrolment/deferment/exemption/registrationDateInfos";
const save = (formData) => {
    return axios.post(BASE_URL , formData,
        {
            headers: authHeader()
        }
    );
};

const getAll = () => {
    return axios.get(BASE_URL,
        { headers: authHeader() });
};



const update = (formData) => {
    return axios.put(BASE_URL + "/updateRegistrationDateInfo", formData,
        {
            headers: authHeader()
        }
    );
};

const getRegistrationDateInfo = () => {
    return axios.get(
        BASE_URL + "/getRegistrationDateInfo",
        {headers: authHeader()}
    );
};

const getRegistrationDateInfoWithCid = (cid,enlistmentYear) => {
    return axios.get(BASE_URL + "/getRegistrationDateInfoWithCid", {
        params: {
            cid,
            enlistmentYear,
        }, headers: authHeader(),
    });
};

const getWhiteListUserByCid = (cid) => {
    return axios.get(BASE_URL + "/getWhiteListUserByCid", {
        params: {cid}, headers: authHeader(),
    });
};
export default {
    save,
    getAll,
    update,
    getRegistrationDateInfo,
    getRegistrationDateInfoWithCid,
    getWhiteListUserByCid
};