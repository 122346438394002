import {
  Box,
  List,
  Avatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { tokens } from "../../theme";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { motion } from "framer-motion";
import bg from "../../assets/background/bg3000.png";
import profileService from "../../services/profile.service";
import HomeIcon from "@mui/icons-material/Home";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import logo from "../../assets/logo/logo-gyalsung.png";
import { useTranslation } from "react-i18next";
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';

const SideMenuStudent = () => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const drawerWidth = 270;
  const navigate = useNavigate();
  const siginUser = JSON.parse(localStorage.getItem("user"));
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    getProfileInfo();
  }, []);

  const getProfileInfo = () => {
    if (siginUser !== null) {
      profileService.getProfileInfo(siginUser.userId).then(
        (response) => {
          setUserInfo(response.data);
        },
        (error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
          );
        }
      );
    }
  };

  function onLangChange(event) {
    setLang(event.target.checked);
    if (event.target.checked) {
      i18n.changeLanguage("dz");
      localStorage.setItem("language", "DZO");
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "ENG");
    }
  }
  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        backgroundColor: colors.main[900],
        height: "100vh",
        paddingY: 0,
        "*::-webkit-scrollbar": {
          width: "3px",
        },
        "*::-webkit-scrollbar-track": {
          background: "#E4EFEF",
        },
        "*::-webkit-scrollbar-thumb": {
          background:
            theme.palette.mode === "dark" ? "#1D388F61" : colors.main[900],
        },
      }}
      aria-label="mailbox folders"
    >
      {/* Desktop */}
      <motion.aside
        initial={{ x: -240 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Drawer
          PaperProps={{
            sx: {
              backgroundImage: `url(${bg})`,
              backgroundRepeat: "repeat",
              backgroundAttachment: "fixed",
              backgroundPosition: "left",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {/*top bar*/}
          <Toolbar
            sx={{
              backgroundColor: "white",
              position: "fixed",
              boxShadow: 1,
              zIndex: 100,
              width: `${drawerWidth}px`,
              background: "linear-gradient(to right, rgb(4 4 4), rgb(4 4 4))",
            }}
          >
            <Stack direction="row" alignItems="center">
              {/* <Typography variant="h6">Side Menu</Typography> */}
              <img
                src={logo}
                alt="logo"
                width={"70%"}
                // height={"60%"}
                // style={{ maxWidth: "100%", paddingBottom: "75%" }}
              />
            </Stack>
          </Toolbar>
          <Toolbar />

          <Box>
            <Box padding={2} sx={{ display: "flex", justifyContent: "center" }}>
              {/* <Avatar
                sx={{
                  width: 150,
                  height: 150,
                  border: "0.2px solid white",
                  cursor: "pointer",
                }}
                src={`data:image/jpeg;base64,${profilePhoto}`}
              /> */}
            </Box>

            <Typography align="center" variant="h6">
              Welcome
            </Typography>
            <Typography align="center" variant="h6" sx={{ fontWeight: "bold" }}>
              {userInfo.fullName}
            </Typography>

            <Box padding={3}>
              <List component="nav" aria-label="main mailbox folders">
                <ListItemButton
                  selected={window.location.href.includes("openUserDashboard")}
                  onClick={() => {
                    navigate("/authenticatedViewWrapper/openUserDashboard");
                  }}
                >
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("Home")} />
                </ListItemButton>

                <ListItemButton
                  selected={window.location.href.includes("nsRegistration")}
                  onClick={() => {
                    navigate("/authenticatedViewWrapper/nsRegistration");
                  }}
                >
                  <ListItemIcon>
                    <PersonAddIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("NS Registration")} />
                </ListItemButton>

                <ListItemButton
                  selected={window.location.href.includes(
                    "applyEarlyEnlistment"
                  )}
                  onClick={() => {
                    navigate(
                      "/authenticatedViewWrapper/myEarlyEnlistmentApplications"
                    );
                  }}
                >
                  <ListItemIcon>
                    <GroupAddIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("Early Enlistment")} />
                </ListItemButton>

                <ListItemButton
                  selected={window.location.href.includes(
                    "myDefermentApplications"
                  )}
                  onClick={() => {
                    navigate(
                      "/authenticatedViewWrapper/myDefermentApplications"
                    );
                  }}
                >
                  <ListItemIcon>
                    <EventRepeatIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("Deferment")} />
                </ListItemButton>

                <ListItemButton
                  selected={window.location.href.includes(
                    "myExemptionApplications"
                  )}
                  onClick={() => {
                    navigate(
                      "/authenticatedViewWrapper/myExemptionApplications"
                    );
                  }}
                >
                  <ListItemIcon>
                    <PersonAddDisabledIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("Exemption")} />
                </ListItemButton>

                <ListItemButton
                  selected={window.location.href.includes("myMedicalDashboard")}
                  onClick={() => {
                    navigate("/authenticatedViewWrapper/myMedicalDashboard");
                  }}
                >
                  <ListItemIcon>
                    <LocalHospitalIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("Medical Screening")} />
                </ListItemButton>

                <ListItemButton
                    selected={window.location.href.includes("enlistmentNotice")}
                    onClick={() => {
                      navigate("/authenticatedViewWrapper/enlistmentNotice");
                    }}
                >
                  <ListItemIcon>
                    <NotificationsNoneOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("Enlistment Notice")} />
                </ListItemButton>

                <ListItemButton
                  selected={window.location.href.includes("gyalsupProfile")}
                  onClick={() => {
                    navigate("/authenticatedViewWrapper/gyalsupProfile");
                  }}
                >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("My Profile")} />
                </ListItemButton>
                <ListItemButton
                  selected={window.location.href.includes("settingDashboard")}
                  onClick={() => {
                    navigate("/authenticatedViewWrapper/settingDashboard");
                  }}
                >
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("Setting")} />
                </ListItemButton>
                {/* <Box padding={4}>
                  <Typography>
                    English
                    <Switch
                      checked={lang}
                      onChange={onLangChange}
                      color="secondary"
                    />
                    རྫོང་ཁ།
                  </Typography>
                </Box> */}
              </List>
            </Box>
          </Box>
        </Drawer>
      </motion.aside>
    </Box>
  );
};

export default SideMenuStudent;
