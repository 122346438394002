import {
    Box,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Slide,
    Stack,
    Typography,
    Collapse,
    IconButton,
    Button,
    Alert
} from "@mui/material";
import React, {useEffect, useState} from "react";
import PageTransitionFadeIn from "../animation/PageAnimation/PageAnimation";
import EarlyEnlistmentInstruction from "./EarlyEnlistmentInstruction";
import GuardianConsent from "./GuardianConsent";
import earlyEnlistmentService from "../../services/earlyEnlistment.service";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {Link, useLocation, useNavigate} from "react-router-dom";
import moment from "moment";
import commonService from "../../services/common.service";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import guardianConsentService from "../../services/guardianConsent.services";
import registrationDateSetupService from "../../services/registrationDateSetup.service";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const ApplyNewEarlyEnlistment = () => {
    // Constant Value
    const dateFormat = "MMMM DD, YYYY";
    const timeFormat = "hh:mm A";
    const approveStatus = "F";
    const rejectStatus = "R";
    const revertStatus = "V";
    const cancelled = "C";
    const pendingStatus = "P";
    let minAgeLimit = 16.05;
    const location = useLocation();
    const enlistmentCurYear = location.state?.enlistmentCurYear;
    const userInfo = location.state?.userInfo;
    const whiteListUser = location.state?.whiteListUser;


    const siginUser = JSON.parse(localStorage.getItem("user"));
    const userId = siginUser.userId;
    const [alertData, setAlertData] = useState({
        show: false,
        severity: "info",
        message: "",
    });
    const navigate = useNavigate();

    // List
    const [guardianInfoList, setGuardianInfoList] = useState([]);
    const [preLatestEnlistmentData, setPreLatestEnlistmentData] = useState([]);
    const [latestGuardianInfo, setLatestGuardianInfo] = useState([]);

    // Other
    const [showRequestConsentDialog, setShowRequestConsentDialog] = useState(false);
    const [enlistmentYear, setEnlistmentYear] = useState([]);
    const [progress, setProgress] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [consentRequestSuccess, setConsentRequestSuccess] = useState(false);
    const [myApplicationDate, setMyApplicationDate] = useState();
    const [earlyEnlistmentStatus, setEarlyEnlistmentStatus] = useState("");
    const [age, setAge] = useState(0);
    const [enlistmentPreYear, setEnlistmentPreYear] = useState([]);
    const [enlistmentCurrentYear, setEnlistmentCurrentYear] = useState("2006");
    const [registrationOpenNow, setRegistrationOpenNow] = useState(true);
    const [registrationEndDate, setRegistrationEndDate] = useState();
    const [enlistmentPrevYear, setEnlistmentPrevYear] = useState("2005");

    const handleRequestConsentDialogClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setShowRequestConsentDialog(false);
    };
    const requestGuardianConsent = () => {
        setProgress(true);
        setShowAlert(false);
        setErrorMsg("");
        let domainName = window.location.origin;
        let data = {userId, domainName,enlistmentYear:enlistmentCurYear};

        earlyEnlistmentService.requestGuardianConsent(data).then(
            (response) => {
                setProgress(false);
                setShowRequestConsentDialog(false);
                setConsentRequestSuccess(true);
            },
            (error) => {
                setProgress(false);
                setShowAlert(true);
                setErrorMsg(error.response.data.message);
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
            }
        );
    };

    const normalizeDate = (date) => {
        const normalized = new Date(date);
        normalized.setHours(0, 0, 0, 0); // Set time to 00:00:00
        return normalized;
    };

    const checkRegistrationDate = (data) => {
        let fromDate = data.fromDate;
        let toDate = data.toDate;
        let status = data.status;
        let currentDate = new Date().getTime();
        // Normalize the dates
        const normalizedCurrentDate = normalizeDate(currentDate);
        const normalizedFromDate = normalizeDate(fromDate);
        const normalizedToDate = normalizeDate(toDate);
        if (normalizedCurrentDate >= normalizedFromDate && normalizedCurrentDate <= normalizedToDate) {
            if (status === "A") {
                setRegistrationOpenNow(true);
            }
        }
    };

    useEffect(() => {
        if(userInfo.cid!==undefined){
            registrationDateSetupService
                .getRegistrationDateInfoWithCid(userInfo.cid, enlistmentCurYear)
                .then(
                    (response) => {
                        if (response.data === "exists") {
                            setRegistrationOpenNow(true);
                        } else {
                            const oneDayLessDate = new Date(response.data.toDate);
                            setRegistrationEndDate(oneDayLessDate);
                            checkRegistrationDate(response.data);
                        }
                    },
                    (error) => {
                        console.log(
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString()
                        );
                    }
                );

        }
    }, [enlistmentCurYear]);


    const getApplicationStatusName = (status) => {
        let statusName = (
            <Chip
                label={<Typography>Pending</Typography>}
                color="warning"
                size="small"
                variant="outlined"
            />
        );

        if (status === approveStatus) {
            statusName = (
                <Chip
                    label={<Typography>Forwarded for medical screening</Typography>}
                    color="success"
                    size="small"
                    variant="outlined"
                />
            );
        }
        if (status === rejectStatus) {
            statusName = (
                <Chip
                    label={<Typography>Rejected</Typography>}
                    color="error"
                    size="small"
                    variant="outlined"
                />
            );
        }
        if (status === revertStatus) {
            statusName = (
                <Chip
                    label={<Typography>Reverted</Typography>}
                    color="error"
                    size="small"
                    variant="outlined"
                />
            );
        }
        if (status === "E") {
            statusName = (
                <Chip
                    label={<Typography>Forwarded for Exemption</Typography>}
                    color="success"
                    size="small"
                    variant="outlined"
                />
            );
        }
        return statusName;
    };


    useEffect(() => {
        commonService.getActiveEnlistmentYear().then(
            (response) => {
                setEnlistmentYear(response.data);
            },
            (error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
            }
        );

        earlyEnlistmentService.getGuardianConsentStatus(enlistmentCurYear).then(
            (response) => {
                setGuardianInfoList(response.data);
            },
            (error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
            }
        );

        earlyEnlistmentService.getEarlyEnlistmentStatus().then(
            (response) => {
                setMyApplicationDate(response.data.application_date);
                setEarlyEnlistmentStatus(response.data.status);
                setEnlistmentPreYear(response.data.enlistment_year);
            },
            (error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
            }
        );


    }, []);

    useEffect(() => {
        if (enlistmentYear.length !== 0) {
            earlyEnlistmentService.getPreLatestEarlyEnlistmentByUserId(siginUser.userId, enlistmentCurYear).then(
                (response) => {
                    setPreLatestEnlistmentData(response.data);
                },
                (error) => {
                    console.log(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                    );
                }
            );
        }

    }, [enlistmentYear]);

    useEffect(() => {
        guardianConsentService
            .getGuardianConsentListByUserId(siginUser.userId,enlistmentCurYear)
            .then((response) => {
                if (response.data !== null) {
                    setLatestGuardianInfo(response.data);
                }
            });
        checkAgeValidation();
    }, []);

    const checkAgeValidation = () => {
        commonService.checkAgeValidation(userId).then(
            (response) => {
                let year = response.data.age;
                let month = response.data.months;
                // let myAge = response.data.age + "." + response.data.months;

                if (month.toString().length < 2) {
                    month = "0" + month;
                }
                let myAge = year + "." + month;
                setAge(myAge);
            },
            (error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
            }
        );
    };


    const getConsentStatusName = (status) => {
        let statusName = (
            <Chip
                label={<Typography>Pending</Typography>}
                color="warning"
                size="small"
                variant="outlined"
            />
        );

        if (status === "A") {
            statusName = (
                <Chip
                    label={<Typography>Supported</Typography>}
                    color="success"
                    size="small"
                    variant="outlined"
                />
            );
        }
        if (status === "R") {
            statusName = (
                <Chip
                    label={<Typography>Not Supported</Typography>}
                    color="error"
                    size="small"
                    variant="outlined"
                />
            );
        }
        return statusName;
    };



    return (
        <PageTransitionFadeIn
            child={
                !registrationOpenNow ? (
                    <Alert icon={false} severity="warning" variant="outlined">
                        <Typography align="justify">
                            Gyalsung 2025 intake is now closed. Should you need
                            assistance, please email registration.ghq@ns.bt or call
                            17 17 28 28.
                        </Typography>
                    </Alert>

                ):((new Date(userInfo.dob).getFullYear()<=(enlistmentCurYear==='2024'?enlistmentPrevYear:enlistmentCurrentYear) || age < minAgeLimit) ? (
                    <>
                        <Alert icon={false} severity="warning" variant="outlined">
                            {age < minAgeLimit ? (
                                <>
                                    <Typography variant="h6" align="center">
                                        You must attain at least the minimum age of {minAgeLimit}{" "}
                                        for early enlistment.
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography variant="h6" align="center">
                                        You are eligible for Gyalsung training. Please proceed
                                        to <Link to="../nsRegistration">{"Registration"}</Link>
                                    </Typography>
                                </>
                            )}
                        </Alert>
                    </>):(

                    (earlyEnlistmentStatus !== "" && earlyEnlistmentStatus !== undefined && earlyEnlistmentStatus !== cancelled
                        && (whiteListUser?(enlistmentPreYear<=enlistmentYear.registrationYear):(enlistmentPreYear===enlistmentYear.registrationYear) )
                    ) ? (
                        <>
                            <Alert
                                icon={false}
                                severity={
                                    preLatestEnlistmentData.status === pendingStatus
                                        ? "warning"
                                        : preLatestEnlistmentData.status === "F"
                                        ? "success"
                                        : "error"
                                }
                                variant="outlined"
                            >
                                <Typography variant="h5" align="justify">
                                    You have already applied for early enlistment on{" "}
                                    {moment(myApplicationDate).format(dateFormat)}, at{" "}
                                    {moment(myApplicationDate).format(timeFormat)}
                                </Typography>
                                <Typography variant="h5" align="justify">
                                    Status:{" "}
                                    {getApplicationStatusName(earlyEnlistmentStatus)}
                                </Typography>
                            </Alert>
                        </>
                    ) : (
                        consentRequestSuccess ? (
                            <Alert icon={false} severity="success" variant="outlined">
                                <Typography
                                    textAlign="center"
                                    variant="h5"
                                    fontWeight="bold"
                                >
                                    <TaskAltIcon style={{fontSize: 30}}/> Application
                                    Success
                                </Typography>
                                <Typography textAlign="justify" variant="h6">
                                    You have successfully requested parent/guardian
                                    consent for early enlistment.{" "}
                                    {userInfo.guardianNameFirst}(Your primary guardian)
                                    will receive an email and SMS regarding your request.
                                    Please come back after your parent/guardian has
                                    consented.
                                </Typography>
                            </Alert>

                        ) : (
                            <>
                                <Box>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={() => {
                                            navigate("/authenticatedViewWrapper/myEarlyEnlistmentApplications")
                                        }}
                                    >
                                        <ChevronLeftOutlinedIcon/> Go Back
                                    </Button>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <br/>
                                    <EarlyEnlistmentInstruction
                                        registrationEndDate={moment(registrationEndDate).format(dateFormat)}
                                        enlistmentCurYear={enlistmentCurYear}
                                    />

                                </Box>
                                <GuardianConsent
                                    guardianInfo={guardianInfoList}
                                    userInfo={userInfo}
                                    dateFormat={dateFormat}
                                    timeFormat={timeFormat}
                                    getConsentStatusName={getConsentStatusName}
                                    setShowRequestConsentDialog={
                                        setShowRequestConsentDialog
                                    }
                                    preLatestEnlistmentData={preLatestEnlistmentData}
                                    latestGuardianInfo={latestGuardianInfo}
                                />
                                {latestGuardianInfo.status === "A" && <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                    paddingTop={2}
                                >
                                    <Collapse in={true}>
                                        <Alert
                                            variant="outlined"
                                            severity="success"
                                            icon={false}
                                            sx={{
                                                mb: 2,
                                                width: "100%",
                                                alignItems: "center",
                                                position: "relative",
                                            }}
                                        >
                                            <Typography align="center" variant="h6">
                                                Your guardian has approved your request to register for Gyalsung training.
                                                You can now register for early enlistment. {""}<Typography>
                                                <Button
                                                    variant="outlined"
                                                    color="info"
                                                    style={{textTransform: "initial"}}
                                                    onClick={() => {
                                                        navigate("applyEarlyEnlistment", {
                                                            state: { enlistmentCurYear },
                                                        });
                                                    }}
                                                >
                                                    <Typography>Please click here to register</Typography>
                                                </Button>
                                            </Typography>

                                            </Typography>
                                        </Alert>
                                    </Collapse>

                                </Box>}
                                {/* request guardian consent dialog */}
                                <Dialog
                                    TransitionComponent={Transition}
                                    open={showRequestConsentDialog}
                                    onClose={handleRequestConsentDialogClose}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{borderTop: "5px solid red"}} // Use sx prop for custom styles
                                >
                                    <DialogContent sx={{borderTop: "6px solid #2f7d31"}}>
                                        <Stack spacing={1}>
                                            <Typography align="center" variant="h3">
                                                <HelpIcon
                                                    sx={{fontSize: "30px", color: "#ed6d03"}}
                                                />{" "}
                                                Confirmation
                                            </Typography>
                                            <Typography align="center" variant="h6">
                                                You are about to request consent from your guardian.
                                                Are you sure?
                                            </Typography>
                                            <Typography align="center" variant="body2">
                                                It cannot be undone once you confirm.
                                            </Typography>
                                        </Stack>
                                    </DialogContent>
                                    <DialogActions sx={{justifyContent: "center"}}>
                                        <Button
                                            disabled={progress}
                                            style={{textTransform: "initial"}}
                                            variant="outlined"
                                            color="warning"
                                            onClick={() => {
                                                setShowRequestConsentDialog(false);
                                            }}
                                        >
                                            <Typography> No, Cancel</Typography>
                                        </Button>

                                        <Button
                                            style={{textTransform: "initial"}}
                                            disabled={progress}
                                            variant="outlined"
                                            color="success"
                                            onClick={() => {
                                                requestGuardianConsent();
                                            }}
                                        >
                                            <Typography>Yes, Sure</Typography>
                                            {progress && (
                                                <CircularProgress
                                                    sx={{
                                                        position: "absolute",
                                                        p: 1,
                                                    }}
                                                />
                                            )}
                                        </Button>
                                    </DialogActions>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={1}
                                        padding={2}
                                    >
                                        <Collapse in={alertData.show}>
                                            <Alert
                                                variant="outlined"
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() => {
                                                            setAlertData({...alertData, show: false});
                                                        }}
                                                    >
                                                        <CloseIcon fontSize="inherit"/>
                                                    </IconButton>
                                                }
                                                severity={alertData.severity}
                                            >
                                                <Typography align="justify">
                                                    {alertData.message}
                                                </Typography>
                                            </Alert>
                                        </Collapse>
                                    </Stack>
                                </Dialog>

                            </>))))


            }
        />
    );

};

export default ApplyNewEarlyEnlistment;