// Method to get registration open msg
import {Dialog, DialogActions, DialogContent, Divider, Slide, Stack} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Typography from "@mui/material/Typography";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import React from "react";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export const GraduateInstruction = ({userInfo, popUpState,setPopUpState}) => {
    const { t } = useTranslation();
    const nonBreakingSpace = String.fromCharCode(160);
    let navigate = useNavigate();

    const handleSkipButton = () => {
        setPopUpState(false);
        // on clicking the skip button, save the status as 'SKIPPED' to  local storage and need to show the popup only if status is not 'SKIPPEd' only
        localStorage.setItem("forceUpdate", "SKIPPED");
    };
    const handleDialogClose = (event, reason) => {
        if (reason && reason == "backdropClick") return;
        setPopUpState(false);
    };
    return (
        <Dialog
            disableEscapeKeyDown={true}
            TransitionComponent={Transition}
            fullWidth={true}
            open={popUpState}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            sx={{borderTop: "5px solid red"}} // Use sx prop for custom styles
        >
            <DialogContent sx={{borderTop: "6px solid red"}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={1}>
                        <Typography align="center" variant="h5">
                            {t("Profile update")}
                        </Typography>
                        <Typography align="justify">
                            {t(
                                "You will need to update the details below in your profile. Without this information, you will be unable to register for National Service. Please ignore this message if you have already updated."
                            )}
                        </Typography>
                        <Typography align="justify">
                            {userInfo.mobileNo !== null ? (
                                <TaskAltIcon
                                    style={{color: "green", fontSize: 16}}
                                ></TaskAltIcon>
                            ) : (
                                <TaskAltIcon
                                    style={{color: "red", fontSize: 16}}
                                ></TaskAltIcon>
                            )}
                            {nonBreakingSpace}
                            {t("Mobile Number(Mandatory)")}
                        </Typography>
                        <Typography align="justify">
                            {userInfo.email !== null ? (
                                <TaskAltIcon
                                    style={{color: "green", fontSize: 16}}
                                ></TaskAltIcon>
                            ) : (
                                <TaskAltIcon
                                    style={{color: "red", fontSize: 16}}
                                ></TaskAltIcon>
                            )}
                            {nonBreakingSpace}
                            {t("Email(Mandatory)")}
                        </Typography>
                        <Typography align="justify">
                            {userInfo.guardianMobileNoFirst !== null ? (
                                <TaskAltIcon
                                    style={{color: "green", fontSize: 16}}
                                ></TaskAltIcon>
                            ) : (
                                <TaskAltIcon
                                    style={{color: "red", fontSize: 16}}
                                ></TaskAltIcon>
                            )}
                            {nonBreakingSpace}
                            {t("Guardian Information(Mandatory)")}
                        </Typography>

                        <Typography align="justify">
                            {userInfo.presentPlaceName !== null ? (
                                <TaskAltIcon
                                    style={{color: "green", fontSize: 16}}
                                ></TaskAltIcon>
                            ) : (
                                <TaskAltIcon
                                    style={{color: "red", fontSize: 16}}
                                ></TaskAltIcon>
                            )}
                            {nonBreakingSpace}
                            {t("Current Address(Mandatory)")}
                        </Typography>
                        <Divider
                            variant="fullWidth"
                            sx={{marginY: 2, borderColor: "#c5c5c5"}}
                        />
                        <Typography align="justify">
                            {nonBreakingSpace}
                            {t("Profile Picture(Optional)")}
                        </Typography>

                        <Typography align="justify">
                            {nonBreakingSpace}
                            {t("Parent Information(Optional)")}
                        </Typography>

                        <Typography align="justify">
                            {nonBreakingSpace}
                            {t("Social Media Links(Optional)")}
                        </Typography>
                    </Stack>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions sx={{justifyContent: "center"}}>
                <Button
                    style={{textTransform: "initial"}}
                    autoFocus
                    variant="outlined"
                    color="success"
                    onClick={() => {
                        navigate("/authenticatedViewWrapper/gyalsupProfile");
                    }}
                >
                    <EditIcon/>
                    <Typography>{t("Update Now")}</Typography>
                </Button>
                {userInfo.mobileNo === null ||
                userInfo.email === null ||
                userInfo.guardianMobileNoFirst === null ||
                userInfo.presentPlaceName === null ? (
                    <></>
                ) : (
                    <Button
                        style={{textTransform: "initial"}}
                        variant="outlined"
                        color="warning"
                        onClick={handleSkipButton}
                    >
                        <Typography>{t("Skip")}</Typography> <SkipNextIcon/>
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};