import {
    Alert,
    Box,
    Chip, CircularProgress,
    Dialog, DialogActions,
    DialogContent, DialogTitle, Grid, IconButton,
    MenuItem,
    Slide, Snackbar,
    Stack,
    TextareaAutosize,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import PageTransitionFadeIn from "../animation/PageAnimation/PageAnimation";
import Button from "@mui/material/Button";
import useComponentBasePremission from "../../CustomHooks/useComponetBasePermission";
import {Link, useLocation, useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import {useFormik} from "formik";
import specialExemptionService from "../../services/specialExemption.service";
import {changeTopBarTitle} from "../../redux/TopBarTitleSetter";
import {useDispatch} from "react-redux";
import commonService from "../../services/common.service";
import common from "../common/common";

import SearchIcon from "@mui/icons-material/Search";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import userService from "../../services/user.service";
import {DataGrid} from "@mui/x-data-grid";
import {CustomNoRowsOverlay} from "../../Overlay/CustomNoRowOverlay";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import remarksStatus from "../../data/remarksStatus.json";
import ReactQuill from "react-quill";
import Collapse from "@material-ui/core/Collapse";
import approvalStatusButton from "../../data/approvalStatusButton.json";
import userRole from "../../data/userRole.json";
import approvalStatus from "../../data/approvalSpecialExemption.json";
import * as Yup from "yup";
import registrationDateSetupService from "../../services/registrationDateSetup.service";
import PhoneInput from "react-phone-input-2";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import 'react-quill/dist/quill.snow.css';
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const SpecialExemptionList = () => {
    const location = useLocation();
    const permissions = useComponentBasePremission("specialExemptionList");
    const [loading, setLoading] = useState(false);
    const valuesCriteria = location.state?.valuesCriteria;
    let navigate = useNavigate();
    const formData = new FormData();
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const userTypes = currentUser.roles.map((role) => role.userType);
    const dispatch = useDispatch();
    const [enlistmentYear, setEnlistmentYear] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showCidAlert, setShowCidAlert] = useState(false);
    const [progress, setProgress] = useState(false);
    const [showErrorFile, setShowErrorFile] = useState(false);
    const [fileError, setFileError] = useState(false);
    const [fileLimit, setFileLimit] = useState(false);
    const [specialExemptionList, setSpecialExemptionList] = useState([])
    const [userTypeRole, setUserTypeRole] = useState("J");
    const [reasonList, setReasonList] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const isAtLeastOneRowSelected = selectionModel.length > 0;
    const [remarksDialog, setRemarksDialog] = useState(false);
    const [showDialogField, setShowDialogField] = useState(true);
    const [status, setStatus] = useState(null);
    const [reviewRemarks, setReviewRemarks] = useState();
    const [showPendingDraftStatus, setShowPendingDraftStatus] = useState(false);
    const [yearList, setYearList] = useState([]);
    const [offset, setOffset] = useState(0); // Initial offset
    const [registrationYear, setRegistrationYear] = useState("");
    const [showIndInformation, setShowIndInformation] = useState(false);
    const [alertData, setAlertData] = useState({ severity: "info", message: "" });
    const [isInProgress, setIsInProgress] = useState(false);
    const [showCircularProgress, setShowCircularProgress] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openApprovalSuccess, setOpenApprovalSuccess] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [warningApprovalMessage, setWarningApprovalMessage] = useState('');
    const [exmptDefer, setExmptDefer] = useState('A');
    const [isLinearProgress, setIsLinearProgress] = useState(false);
    const [previousExemptionList, setPreviousExemptionList] = useState([]);
    const [fullNameApp, setFullNameApp] = useState("");
    const [cidApp, setCidApp] = useState("");
    const [popUpState, setPopUpState] = useState(false);
    const nonBreakingSpace = String.fromCharCode(160);
    const [showMore, setShowMore] = useState(false);
    const [showMoreLabel, setShowMoreLabel] = useState("More");


    const MAX_COUNT = 5;
    const allStatus = "ALL";
    const reviewStatus = "T";
    const approveStatus = "A";
    const rejectStatus = "R";
    const sendMail = "M";
    const pendingApproval = "N";

    const pendingStatus = "P";
    const pendingRejection = "S";
    const draftStatus = "D";
    const seniorUserRole = Object.keys(userRole).find(
        key => userRole[key] === "Senior Officer");
    const juniorUserRole = Object.keys(userRole).find(key => userRole[key] === "Junior Officer");
    const maleStatus = "M";
    const feMaleStatus = "F";
    const [prevValue, setPrevValue] = useState(false);
    const handleEditorChange = (value) => {
        setTextContent(value);
    };
    const rejectionMailContent =
        "Dear sir/madam" +
        ",<br/><p>Your application for Exemption has been reviewed. Gyalsung HQ regret to inform you that your application is not APPROVED.</p><p> You are required to still report for Gyalsung Training as per the enlistment letter to be sent to you.<p>Should you wish to APPEAL, please proceed to the Gyalsung Portal and submit your application.</p>Gyalsung HQ will strive to revert to you as soon as possible, should you have any other query, please call the call centre 2024 or email _____@ns.bt , please state your application number and CID in all your email correspondence.</p>" +
        "<br>Thank you<br></br><small>***This is a system-generated email. Please do not respond to this email.***</small><br></br>";
    const approveMailContent =
        "Dear sir/madam"+
        ",<p>We are pleased to inform you that you have been granted a Exemption from Gyalsung Training 2024. </p>" +
        "<br>Thank you<br>Palden Drukpa Gyalo<br><br><small>***This is a system-generated email. Please do not respond to this email.***</small><br></br>";

    const [textContent, setTextContent] = useState(
        status === approveStatus
            ? approveMailContent
            : rejectionMailContent
    );


    //Initial Functions
    useEffect(() => {
        setFieldValueSearch("userRole",common.getUserRole(userTypes));
        setUserTypeRole(common.getUserRole(userTypes));
        getReasonList();
        // change top bar title
        dispatch(changeTopBarTitle("Special Exemption List"));
        getEnlistmentDateInfo();
        getActiveEnlistmentYear();
        return () =>
            uploadedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
        // for file dropzone
    }, []);

    const handleCloseSuccess = () => {
        setOpenSuccess(false);
    };

    const handleApprovalCloseSuccess = () => {
        setOpenApprovalSuccess(false);
    };

    const getActiveEnlistmentYear = () => {
        commonService.getActiveEnlistmentYear().then(
            (response) => {
                setEnlistmentYear(response.data);
            },
            (error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
            }
        );
    };
    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    };


    const [showAddNewSpecialExemptionDialog, setShowAddNewSpecialExemptionDialog] =
        useState(false);
    // Formik value to initialize values and save
    const {values, setFieldValue, resetForm, handleSubmit, touched, errors} = useFormik({
        initialValues: {
            reasonId: "",
            gender: "",
            mobileNo:"",
            email:"",
            name:"",
            proofDocuments: null,
            dateOfBirth:"",
            cid:"",
            exmptDefer:exmptDefer
        },
        onSubmit: (values) => {
            submitForm().then((r) => {
            });
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("This field is required"),
            cid: Yup.string().required("This field is required"),
            dateOfBirth: Yup.string().required("This field is required"),
            reasonId: Yup.string().required("This field is required"),
            mobileNo: Yup.string().required("This field is required"),
            proofDocuments: Yup.mixed().test(
                "fileUpload",
                "Please upload at least one file",
                function (value) {
                    if (uploadedFiles.length <= 0) {
                        return this.createError({
                            path: this.path,
                            message: "Please upload at least one file",
                        });
                    }
                    return true;
                }
            ),
        }),
    });

    const handlePhoneChange = (value, country) => {
       setFieldValue("mobileNo", value);
    };

    const {
        values: valuesSearch,
        setFieldValue: setFieldValueSearch,
        handleSubmit: handleSubmitSearch,
        resetForm: resetFormSearch,
        touched: touchedSearch,
        errors: errorsSearch,
    } = useFormik({
        initialValues: {
            specialExemptionYear: registrationYear,
            status: pendingStatus,
            mailStatus: "",
            gender: "",
            cid: "",
            offset: 0,
            pageSize: 1000,
            dobYear:"",
            userRole:userTypeRole,
            reasonId:""
        },
        onSubmit: (valuesSearch) => {
            setPrevValue(false);
            setFieldValueSearch("offset", 0);
            setOffset(0);
            handleSearch("N", 0, false);
        },
    });

    useEffect(() => {
        if (offset !== 0) {
            if (prevValue) {
                handleSearch("Y", offset, true);
            } else {
                handleSearch("Y", offset, false);
            }
        }
    }, [offset]);


    // Initial methods to run
    useEffect(() => {
        if (valuesCriteria !== undefined) {
            if (
                valuesCriteria.gender === "" &&
                valuesCriteria.cid === "" &&
                valuesCriteria.caseNumber === ""
            ) {
                setShowMore(false);
                setShowMoreLabel(<Typography>More</Typography>);
            } else {
                setShowMore(true);
                setShowMoreLabel(<Typography>Less</Typography>);
            }
            setPrevValue(true);
            setFieldValue("reasonId", valuesCriteria.reasonId);
            setFieldValueSearch("specialExemptionYear", valuesCriteria.specialExemptionYear);
            setFieldValueSearch("status", valuesCriteria.status);
            setFieldValueSearch("mailStatus", valuesCriteria.mailStatus);
            setFieldValueSearch("gender", valuesCriteria.gender);
            setFieldValueSearch("cid", valuesCriteria.cid);
            setFieldValueSearch("dobYear", valuesCriteria.dobYear);
            setFieldValueSearch("userRole", valuesCriteria.userRole);
            handleSearch("N", 0, true);
        }
    }, [valuesCriteria]);

    useEffect(() => {
        const tmpYr = [];
        for (let i = 2024; i <= enlistmentYear.registrationYear; i++) {
            tmpYr.push(i);
        }
        setYearList(tmpYr);
    }, [enlistmentYear]);

    const approvalBulkForm = (e) => {
        setProgress(true);
        setShowCircularProgress(true);
        // Access the selected rows using the selectionModel
        // const selectedRows = selectionModel.map((index) => fileData[index]);
        const selectedRows = selectionModel.map((id) =>
            specialExemptionList.find((row) => row.id === id)
        );
        formData.append("specialExemptionIds", JSON.stringify(selectedRows.map((row) => row.id)));
        formData.append("userId", currentUser.userId);
        formData.append("exemptionYear", enlistmentYear.registrationYear);
        formData.append("userRole", userTypeRole);
        formData.append("status", status);
        formData.append("reviewRemarks", reviewRemarks);
     if (status === sendMail) {
            setProgress(true);
         formData.append("emailContent", textContent);
            specialExemptionService
                .mailSendBulkToApplicant(formData)
                .then((res) => {
                    setRemarksDialog(false);
                    setProgress(false);
                    setShowCircularProgress(false);
                    setOpenApprovalSuccess(true);
                    setWarningApprovalMessage("Mail sent successfully.");
                    handleSearch("N", 0, false);
                });
        }else{
         specialExemptionService.approvalBulkSpecialExemption(formData)
             .then((response) => {
                 setRemarksDialog(false);
                 setOpenApprovalSuccess(true);
                 setWarningApprovalMessage("Data "+approvalStatus[status]+" successfully.");
                 handleSearch("N", 0, false);
                 setProgress(false);
                 setShowCircularProgress(false);

             })
             .catch((error) => {
             });
      }


    };

    const getReasonList = () => {
        commonService.getActiveSpecialExemptionReason().then(
            (response) => {
                setReasonList(response.data);
            },
            (error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
            }
        );
    };

    const getEnlistmentDateInfo = () => {
        registrationDateSetupService.getRegistrationDateInfo().then(
            (response) => {
                if (response.data !== null) {
                    setFieldValue("specialExemptionYear", response.data.registrationYear);
                    setRegistrationYear(response.data.registrationYear);
                }
            },
            (error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
            }
        );
    };
    //Save Method
    const submitForm = async () => {
        setProgress(true);
        //Setting Parameters
        formData.append("exemptionYear", enlistmentYear.registrationYear);
        formData.append("userId", currentUser.userId);
        formData.append("gender", values.gender);
        formData.append("cid", values.cid);
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("mobileNo", values.mobileNo);
        formData.append("userRole", userTypeRole);
        formData.append("reasonId", values.reasonId);
        formData.append("dateOfBirth", values.dateOfBirth);
        formData.append("exmptDefer", exmptDefer);
        for (let i = 0; i < uploadedFiles.length; i++) {
            formData.append("proofDocuments", uploadedFiles[i]);
        }
        //Save Method
        await specialExemptionService
            .save(formData)
            .then((res) => {
                setShowAddNewSpecialExemptionDialog(false);
                setOpenSuccess(true);
                setWarningMessage("Data saved successfully.");
                setProgress(false);
                handleSearch(userTypeRole);
            })
            .catch((error) => {
            });
    };

    const handleAddNewSpecialExemptionDialog = (event, reason) => {
        if (reason && reason == "backdropClick") return;
        if (event && event.key === "Escape") {
            event.stopPropagation();
            event.preventDefault();
            return;
        }
        setShowAddNewSpecialExemptionDialog(false);
    };

    //Validation to check file extension and  get file size
    const handleFileEvent = (e) => {
        touched.proofDocuments = false;
        errors.proofDocuments = false;
        setShowErrorFile(false);
        const selectedFiles = e.target.files;
        const acceptedFileTypes = [".pdf", ".png", ".jpg", ".jpeg"];
        const maxSizeInBytes = 2 * 1024 * 1024;
        // Perform additional validation
        const validFiles = Array.from(selectedFiles).filter((file) => {
            const fileType = file.type;
            const fileExtension = `.${fileType.split("/")[1]}`;
            return (
                acceptedFileTypes.includes(fileExtension) && file.size <= maxSizeInBytes
            );
        });
        if (validFiles.length !== 0) {
            const chosenFiles = Array.prototype.slice.call(validFiles);
            uploadFileHandler(chosenFiles);
        } else {
            setShowErrorFile(true);
            setFileError("The file size must be less than 2 MB.");
        }
    };

    //Validation for file upload(File upload exceed more than 5)
    const uploadFileHandler = (files) => {
        const uploaded = [...uploadedFiles];

        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    setFileError(`You can only add maximum of ${MAX_COUNT} files`);
                    setShowErrorFile(true);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
            return false;
        });
        if (!limitExceeded) setUploadedFiles(uploaded);
    };

    // Method to preview file
    const previewFile = (file) => {
        const fileType = file.type.split("/")[0];

        if (fileType !== "") {
            const url = URL.createObjectURL(file);
            window.open(url, "_blank");
        }
    };

    //Method for file remove
    const removeFile = (fileName) => {
        setUploadedFiles((current) =>
            uploadedFiles.filter((file) => {
                setFileLimit(false);
                return file.name !== fileName;
            })
        );
    };
    const checkAlreadyExistApp = () => {
        commonService.getDefermentExemptionByCid(values.cid).then(
            (response) => {
                if(response.status===200){
                    if(response.data === ''){
                        getCidDetails();
                    }else{
                        setExmptDefer(response.data.exmptDefer);
                        if(response.data.status === 'A' && response.data.mailStatus === 'S'){
                            setShowIndInformation(false);
                            setShowCidAlert(true);
                            setAlertData({
                                severity: "warning",
                                message:response.data.fullName + " has already submitted  "+(response.data.exmptDefer==='D'?"deferment":"exemption")+
                                    " application from "+(response.data.exmptDefer==='D'?"deferment":"exemption")+" list page, which has already been approved."
                            });
                            setIsInProgress(false);
                            setShowCircularProgress(false);
                        }
                        if(response.data.mailStatus!=='S'){
                            setShowIndInformation(true);
                            setShowCidAlert(true);
                            setAlertData({
                                severity: "warning",
                                message:response.data.fullName + " has already submitted an "+(response.data.exmptDefer==='D'?"deferment":"exemption")+" application from "+(response.data.exmptDefer==='D'?"deferment":"exemption")+" list page, which is in "+approvalStatus[response.data.status].toLowerCase()
                                    +" state and mail "+(response.data.mailStatus!=='S'?"not sent.":"sent.") +" If you continue, then you won't be able to do any operation from "+(response.data.exmptDefer==='D'?"deferment":"exemption")+" list page for this user."
                            });
                            getCidDetails();
                        }

                    }

                }else{
                    getCidDetails();
                }

            },
            (error) => {
                setShowCidAlert(true);
                setAlertData({
                    severity: "warning",
                    message:
                        error.response &&
                        error.response.data &&
                        error.response.data.message,
                });
            }
        );
    };

    const handleCidChange = () => {
        setIsInProgress(true);
        setShowCircularProgress(true);
        setFieldValue("name", "");
        setFieldValue("gender", "");
        specialExemptionService.getSpecialExemptionByCid(values.cid).then(
            (response) => {
                if(response.status === 200){
                    if(response.data === ''||response.data.length===0){
                        checkAlreadyExistApp();
                    }else{
                        if(response.data.status === 'A' && response.data.mailStatus === 'S'){
                            setShowIndInformation(false);
                            setShowCidAlert(true);
                            setAlertData({
                                severity: "warning",
                                message:response.data.fullName + " has already submitted an exemption application, which has already been approved."
                            });
                            setIsInProgress(false);
                            setShowCircularProgress(false);
                        }
                        if(response.data.status === 'R' && response.data.mailStatus === 'S'){
                            setShowIndInformation(false);
                            setShowCidAlert(true);
                            setAlertData({
                                severity: "warning",
                                message:response.data.fullName + " has already submitted an exemption application, which has already been rejected."
                            });
                            setIsInProgress(false);
                            setShowCircularProgress(false);
                            getCidDetails();

                        }
                        if((response.data.mailStatus !== 'S')){
                            setShowIndInformation(false);
                            setShowCidAlert(true);
                            setAlertData({
                                severity: "warning",
                                message:response.data.fullName + " has already submitted an exemption application, which is in "+approvalStatus[response.data.status].toLowerCase()
                                    +" state and mail "+(response.data.mailStatus!=='S'?"not sent.":"sent.")+" This user can not continue. In order to continue, application need to be rejected."
                            });
                            setIsInProgress(false);
                            setShowCircularProgress(false);
                        }
                    }
                }

            },
            (error) => {
                setShowCidAlert(true);
                setAlertData({
                    severity: "warning",
                    message:
                        error.response &&
                        error.response.data &&
                        error.response.data.message,
                });
            }
        );

    };

    const getCidDetails = () =>{
        userService.getCensusDetailByCid(values.cid).then(
            (response) => {
                setShowIndInformation(false);
                if(response.status === 200){
                    setShowIndInformation(true);
                    if(alertData.message.length === 0){
                        setShowCidAlert(false);
                    }
                    setIsInProgress(false);
                    setShowCircularProgress(false);
                    setFieldValue("name", response.data.fullName);
                    setFieldValue("gender", response.data.gender);
                    setFieldValue("dateOfBirth", response.data.dob);
                }

            },
            (error) => {
                setShowCidAlert(true);
                setAlertData({
                    severity: "warning",
                    message:
                        error.response &&
                        error.response.data &&
                        error.response.data.message,
                });
                setShowIndInformation(false);
                setIsInProgress(false);
                setShowCircularProgress(false);
            }
        );
    }

    //Method to search
    const handleSearch = async (addMore, offset, prevValue) => {
        if (offset === 0) {
            setIsLinearProgress(true);
        }
        let gender = valuesSearch.gender;
        let cid = valuesSearch.cid;
        let mailStatus = valuesSearch.mailStatus;
        let dobYear = valuesSearch.dobYear;
        let status = valuesSearch.status;
        let userRole = userTypeRole;
        let reasonId = valuesSearch.reasonId;
        setFieldValueSearch("userRole",userTypeRole);
        let specialExemptionYear = valuesSearch.specialExemptionYear;
        if (valuesSearch.gender === allStatus) {
            gender = "";
        }
        if (valuesSearch.mailStatus === allStatus) {
            mailStatus = "";
        }
        if (valuesSearch.dobYear === allStatus) {
            dobYear = "";
        }
        if (valuesSearch.reasonId === allStatus) {
            reasonId = "";
        }

        if (prevValue) {
            reasonId = valuesCriteria.reasonId;
            mailStatus = valuesCriteria.mailStatus;
            status = valuesCriteria.status;
            gender = valuesCriteria.gender;
            cid = valuesCriteria.cid;
            dobYear = valuesCriteria.dobYear;
            specialExemptionYear = valuesCriteria.specialExemptionYear;
            userRole = valuesCriteria.userRole;
        }
        await specialExemptionService
            .getSpecExemptionListByCriteria(userRole,status,
                gender,cid,mailStatus,dobYear,specialExemptionYear,offset,
                valuesSearch.pageSize,reasonId)
            .then(
                (response) => {
                    if (addMore === 'N') {
                        setSpecialExemptionList(response.data);
                        setOffset(response.data.length );

                    } else {
                        setSpecialExemptionList([...specialExemptionList, ...response.data])
                        setOffset(specialExemptionList.length + response.data.length);
                    }
                    if (response.data.length === 0) {
                        setIsLinearProgress(false);
                    }
                },
                (error) => {
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
            );
    };

    //Method to get previous exemption list
    const getPreviousSExemptionList = (userId) => {
        specialExemptionService.getSpecialExemptionListByCid(userId).then((response) => {
            if (response.data.length !== 0) {
                setPreviousExemptionList(response.data);
            }
        });
    };


    //Column header list
    const columns = [
        {
            field: "slNo",
            headerName: "SL No",
            flex: 0.1,
            sortable: false,
            filterable: false,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <div>
                    {params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1}
                </div>
            ),
        },
        {
            field: "cid",
            headerName: "CID",
            flex: 1,
            headerAlign: "left",
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "fullName",
            headerName: "Name",
            flex: 1,
            headerAlign: "left",
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "mobileNo",
            headerName: "Mobile",
            flex: 0.5,
            headerAlign: "left",
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            headerAlign: "left",
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "dob",
            headerName: "DOB",
            flex: 0.7,
            hide: true,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "gender",
            headerName: "Gender",
            flex: 0.4,
            headerClassName: "super-app-theme--header",
            valueFormatter: (params) => {
                const genderValue = params.value;
                if (genderValue === "M") {
                    return "Male";
                } else if (genderValue === "F") {
                    return "Female";
                } else {
                    return genderValue; // If you want to display the original value for other cases
                }
            },
        },
        {
            field: "exemptionYear",
            headerName: "Exemption Year",
            flex: 0.4,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "applicationDateInString",
            headerName: "Registration Date",
            flex: 0.6,
            hide: true,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                const cellDate = new Date(params.row.applicationDate);
                const currentDate = new Date();
                const daysDifference = Math.floor(
                    (currentDate - cellDate) / (1000 * 60 * 60 * 24)
                );

                let cellColor = "inherit";
                if (daysDifference > 14) {
                    cellColor = "red"; // Change to the desired color for dates older than 14 days
                }

                return <div style={{color: cellColor}}>{params?.value}</div>;
            },
        },
        {
            field: "reasonId",
            headerName: "Reason",
            flex: 0.5,
            headerClassName: "super-app-theme--header",
            valueFormatter: (params) => {
                return reasonList.find((r) => r.reasonId === params.value)?.reasonName;
            },
        },
        {
            field: "mailStatusName",
            headerName: "Email Sent",
            flex: 0.5,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "statusName",
            headerName: "Status",
            flex: 0.5,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "exemptionAppealLength",
            headerName: "A.D",
            flex: 0.3,
            headerAlign: "center",
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Link
                    to="#"
                    component="button"
                    variant="body2"
                    onClick={(e) => {
                        getPreviousSExemptionList(params.row.cid);
                        setPopUpState(true);
                        setFullNameApp(params.row.fullName);
                        setCidApp(params.row.cid);
                    }}
                >
                    {params.value}
                </Link>
            ),
        },
        {
            field: "action",
            headerName: "",
            flex: 0.1,
            headerClassName: "super-app-theme--header",
            disableColumnMenu: false,
            renderCell: (params) => (
                <Tooltip title="Click here to review">
                    <KeyboardArrowRightIcon
                        style={{
                            color: "black",
                            cursor: "pointer",
                        }}
                    />
                </Tooltip>
            ),
        },
    ];

    return (
        <PageTransitionFadeIn
            child={
                <>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                    >
                        <Typography sx={{ color: "grey" }} variant="h5">
                            <Button
                                disabled={loading}
                                style={{textTransform: "initial"}}
                                size="small"
                                variant="outlined"
                                color="info"
                                onClick={() => {
                                    resetForm();
                                    setShowCidAlert(false);
                                    setProgress(false);
                                    setIsInProgress(false);
                                    setShowIndInformation(false);
                                    setShowAddNewSpecialExemptionDialog(true);
                                    setShowCircularProgress(false);
                                }}
                            >
                                <Typography>Add New Special Exemption</Typography>
                            </Button>
                        </Typography>
                        {permissions[0].save && (
                            <Button
                                disabled={loading}
                                style={{ textTransform: "initial" }}
                                size="small"
                                variant="outlined"
                                color="info"
                                onClick={() => {
                                    navigate("specialExemptionBulk");
                                }}
                            >
                                <Typography>Add Bulk Special Exemption</Typography>
                            </Button>
                        )}
                    </Stack>
                    <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop={1}
                    >
                        <Grid item xs={12} sm={2}>
                            {/*status*/}
                            <TextField
                                select
                                fullWidth
                                size="small"
                                label="Status"
                                value={valuesSearch.status || pendingStatus || reviewStatus}
                                onChange={(e) => setFieldValueSearch("status", e.target.value)}
                            >
                                {Object.keys(approvalStatus).map((value) => {
                                    if (value !== "M") {
                                        return (
                                            <MenuItem key={value} value={value}>
                                                {approvalStatus[value]}
                                            </MenuItem>
                                        );
                                    }
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {/*mail status*/}
                            <TextField
                                select
                                fullWidth
                                size="small"
                                // disabled={isInProgress}
                                label="Mail Status"
                                value={valuesSearch.mailStatus}
                                onChange={(e) => setFieldValueSearch("mailStatus", e.target.value)}
                            >
                                <MenuItem key="" value={allStatus}>
                                    ALL
                                </MenuItem>
                                <MenuItem value="N">Not Sent</MenuItem>
                                <MenuItem value="S">Sent</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                fullWidth
                                required
                                select
                                label="Enlistment Year"
                                placeholder="Year"
                                size="small"
                                value={values.exemptionYear}
                                onChange={(e) => setFieldValueSearch("exemptionYear", e.target.value)}
                            >
                                {yearList.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {/*Gender*/}
                            <TextField
                                select
                                fullWidth
                                size="small"
                                // disabled={isInProgress}
                                label="Gender"
                                value={valuesSearch.gender}
                                onChange={(e) => setFieldValueSearch("gender", e.target.value)}
                            >
                                <MenuItem key="" value={allStatus}>
                                    ALL
                                </MenuItem>
                                <MenuItem value={maleStatus}>Male</MenuItem>
                                <MenuItem value={feMaleStatus}>Female</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                label="Reason"
                                value={valuesSearch.reasonId}
                                onChange={(e) => setFieldValueSearch("reasonId", e.target.value)}
                                select
                                size="small"
                                fullWidth
                            >
                                <MenuItem key="" value={allStatus}>
                                    ALL
                                </MenuItem>
                                {reasonList.map((items) => {
                                    return (
                                        <MenuItem key={items.reasonId} value={items.reasonId}>
                                            {items.reasonName}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {permissions[0].view && (
                                <Button
                                    color="info"
                                    style={{textTransform: "initial"}}
                                    disabled={isInProgress}
                                    variant="outlined"
                                    onClick={handleSubmitSearch}
                                    startIcon={<SearchIcon/>}
                                >
                                    <Typography> Search</Typography>
                                </Button>
                            )}
                            <Button
                                startIcon={
                                    showMore ? (
                                        <RemoveCircleOutlineOutlinedIcon/>
                                    ) : (
                                        <AddCircleOutlineOutlinedIcon/>
                                    )
                                }
                                color="info"
                                style={{textTransform: "initial"}}
                                disabled={isInProgress}
                                variant="outlined"
                                onClick={(e) => {
                                    setFieldValue("cid", "");
                                    if (showMore) {
                                        setShowMore(false);
                                        setShowMoreLabel(<Typography>More</Typography>);
                                    } else {
                                        setShowMore(true);
                                        setShowMoreLabel(<Typography>Less</Typography>);
                                    }
                                }}
                            >
                                {showMoreLabel}
                            </Button>
                        </Grid>
                    </Grid>

                    {/* Search criteria */}
                    {showMore && (
                    <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop={1}
                    >

                        <Grid item xs={7} sm={2}>
                            <TextField
                                value={valuesSearch.cid}
                                label="CID"
                                placeholder="CID"
                                size="small"
                                fullWidth
                                onChange={(e) => setFieldValueSearch("cid", e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    )}
                    <br/>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                    >
                        {/*{permissions[0].save &&(userTypeRole === juniorUserRole) && (*/}
                        {/*    <Stack direction="row" spacing={1} justifyContent="flex-end">*/}
                        {/*        <Button*/}
                        {/*            disabled={loading}*/}
                        {/*            style={{textTransform: "initial"}}*/}
                        {/*            size="small"*/}
                        {/*            variant="outlined"*/}
                        {/*            color="info"*/}
                        {/*            onClick={() => {*/}
                        {/*                resetForm();*/}
                        {/*                setShowCidAlert(false);*/}
                        {/*                setProgress(false);*/}
                        {/*                setIsInProgress(false);*/}
                        {/*                setShowIndInformation(false);*/}
                        {/*                setShowAddNewSpecialExemptionDialog(true);*/}
                        {/*                setShowCircularProgress(false);*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <Typography>Add New Special Exemption</Typography>*/}
                        {/*        </Button>*/}
                        {/*        <Button*/}
                        {/*            disabled={loading}*/}
                        {/*            style={{textTransform: "initial"}}*/}
                        {/*            size="small"*/}
                        {/*            variant="outlined"*/}
                        {/*            color="info"*/}
                        {/*            onClick={() => {*/}
                        {/*                navigate("specialExemptionBulk");*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <Typography>Add Bulk Special Exemption</Typography>*/}
                        {/*        </Button>*/}
                        {/*    </Stack>*/}
                        {/*)}*/}


                        {(valuesSearch.status === approveStatus ||
                            valuesSearch.status === rejectStatus) && isAtLeastOneRowSelected && (userTypeRole === juniorUserRole) && <Button
                            disabled={loading}
                            style={{textTransform: "initial"}}
                            size="small"
                            variant="outlined"
                            color="info"
                            onClick={() => {
                                setRemarksDialog(true);
                                if(valuesSearch.status === approveStatus){
                                    setTextContent(approveMailContent);
                                }
                                if(valuesSearch.status===rejectStatus){
                                    setTextContent(rejectionMailContent);
                                }
                                setStatus(sendMail);
                                setReviewRemarks("");
                            }}
                        >
                            <Typography>Send Mail</Typography>
                        </Button>}
                        <div style={{ display: 'flex', gap: '8px' }}>
                            {valuesSearch.status === reviewStatus && isAtLeastOneRowSelected && (userTypeRole === seniorUserRole) && <Button
                                disabled={loading}
                                style={{textTransform: "initial"}}
                                size="small"
                                variant="outlined"
                                color="success"
                                onClick={() => {
                                    setRemarksDialog(true);
                                    setShowPendingDraftStatus(true);
                                    setStatus(draftStatus);
                                }}
                            >
                                <Typography>Draft</Typography>
                            </Button>}
                            {valuesSearch.status === reviewStatus && isAtLeastOneRowSelected && (userTypeRole === seniorUserRole) && <Button
                                disabled={loading}
                                style={{textTransform: "initial"}}
                                size="small"
                                variant="outlined"
                                color="success"
                                onClick={() => {
                                    setRemarksDialog(true);
                                    setShowPendingDraftStatus(false);
                                    setStatus(approveStatus);
                                    setReviewRemarks("");
                                }}
                            >
                                <Typography>Approve</Typography>
                            </Button>}
                            {valuesSearch.status === reviewStatus && isAtLeastOneRowSelected && (userTypeRole === seniorUserRole) && <Button
                                disabled={loading}
                                style={{textTransform: "initial"}}
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                    setRemarksDialog(true);
                                    setShowPendingDraftStatus(false);
                                    setStatus(rejectStatus);
                                    setReviewRemarks("");

                                }}
                            >
                                <Typography>Reject</Typography>
                            </Button>}
                            {(valuesSearch.status === pendingStatus) && isAtLeastOneRowSelected && (userTypeRole === juniorUserRole) && <Button
                                disabled={loading}
                                style={{textTransform: "initial"}}
                                size="small"
                                variant="outlined"
                                color="info"
                                onClick={() => {
                                    setRemarksDialog(true);
                                    setStatus(reviewStatus);
                                    setReviewRemarks("");
                                }}
                            >
                                <Typography>Review</Typography>
                            </Button>}
                        </div>



                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                        marginTop={2}
                    >
                    {/*Grid*/}
                    <DataGrid
                        checkboxSelection
                        density="compact"
                        columns={columns}
                        rows={specialExemptionList}
                        autoHeight
                        disableSelectionOnClick
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        onCellClick={(params) => {
                            const {field} = params.colDef;
                            if (field === "action") {
                                navigate("specialExemptionDetails", {
                                    state: {
                                        data: params.row,
                                        userTypes: userTypes,
                                        reasonList: reasonList,
                                        currentUserId: currentUser.userId,
                                        values: valuesSearch,
                                    },
                                });
                            }
                        }}
                        selectionModel={selectionModel}
                        onSelectionModelChange={handleSelectionModelChange}
                        isRowSelectable={(params) => params.row.disableData !== 'Y'}

                    />
                        <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleCloseSuccess}
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                  }}>
                            <Alert severity="warning">{warningMessage}</Alert>
                        </Snackbar>
                        <Snackbar open={openApprovalSuccess} autoHideDuration={2000} onClose={handleApprovalCloseSuccess}
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                  }}>
                            <Alert severity="success">{warningApprovalMessage}</Alert>
                        </Snackbar>
                    </Stack>

                    {/* Add new receipient dialog */}
                    <Dialog
                        TransitionComponent={Transition}
                        fullWidth={true}
                        open={showAddNewSpecialExemptionDialog}
                        onClose={handleAddNewSpecialExemptionDialog}
                        aria-labelledby="alert-dialog-title"
                    >
                        <DialogContent>
                            <Stack width="100%" display="flex" marginBottom={2}>
                                <Typography variant="h5">Please fill up the details</Typography>
                            </Stack>
                            <Stack width="100%" display="flex" marginBottom={2}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <TextField
                                        size="small"
                                        label="CID"
                                        disabled={isInProgress}
                                        inputProps={{maxLength: 200}}
                                        placeholder="CID"
                                        multiline
                                        fullWidth
                                        value={values.cid}
                                        onChange={(event) =>
                                            setFieldValue("cid", event.target.value)
                                        }
                                        error={touched.cid && errors.cid}
                                        helperText={touched.cid && errors.cid ? "Required" : ""}
                                        style={{flex: "70%", marginRight: "10px"}}
                                    />
                                    <Button
                                        style={{flex: "30%", textTransform: "initial"}}
                                        variant="outlined"
                                        disabled={isInProgress}
                                        onClick={handleCidChange}
                                        startIcon={<SearchIcon/>}
                                    >
                                        Search
                                    </Button>
                                    {showCircularProgress && <CircularProgress size="1rem" />}

                                </Box>
                                <Collapse in={showCidAlert}>
                                    <Alert
                                        variant="outlined"
                                        severity={alertData.severity}
                                        action={
                                            <IconButton onClick={() => setShowCidAlert(false)}>
                                                <CloseIcon />
                                            </IconButton>
                                        }
                                    >
                                        <Typography>{alertData.message}</Typography>
                                    </Alert>
                                </Collapse>
                            </Stack>

                            {/* <Stack width="100%" display="flex" marginBottom={2}> */}
                            <Stack direction="row" spacing={2} width="100%" marginBottom={2}>
                                <TextField
                                    size="small"
                                    label="Full name"
                                    inputProps={{maxLength: 255}}
                                    placeholder="Full name"
                                    multiline
                                    fullWidth
                                    disabled={true}
                                    required
                                    value={values.name}
                                />
                                <TextField
                                    fullWidth
                                    placeholder="Gender"
                                    label="Gender"
                                    required
                                    size="small"
                                    disabled={true}
                                    inputProps={{maxLength: 200, autoComplete: "off"}}
                                    value={values.gender}
                                    onChange={(e) =>
                                        setFieldValue("gender", e.target.value)
                                    }
                                    error={errors.gender && errors.gender}
                                    helperText={touched.gender && touched.gender}
                                />
                                <TextField
                                    size="small"
                                    label="Date of Birth"
                                    inputProps={{ maxLength: 255 }}
                                    multiline
                                    fullWidth
                                    disabled={true}
                                    required
                                    value={values.dateOfBirth}
                                />
                            </Stack>
                            {showIndInformation &&
                            <Stack width="100%" display="flex" marginBottom={2}>
                                <PhoneInput
                                    inputStyle={{ width: "100%" }}
                                    enableSearch
                                    country={"bt"}
                                    value={values.mobileNo}
                                    onChange={handlePhoneChange}
                                    helperText={
                                        touched.mobileNo && errors.mobileNo
                                    }
                                    error={touched.mobileNo && errors.mobileNo}

                                />
                            </Stack>}
                            {showIndInformation && <Stack width="100%" display="flex" marginBottom={2}>
                                <TextField
                                    fullWidth
                                    type="email"
                                    placeholder="Email"
                                    label="Email"
                                    required
                                    size="small"
                                    inputProps={{maxLength: 200, autoComplete: "off"}}
                                    value={values.email}
                                    onChange={(e) => setFieldValue("email", e.target.value)}
                                />
                            </Stack>}
                            {showIndInformation && <Stack width="100%" display="flex" marginBottom={2}>
                                <TextField
                                    label="Reason for Special Exemption"
                                    value={values.reasonId}
                                    onChange={(e) => {
                                        setFieldValue("reasonId", e.target.value);
                                    }}
                                    select
                                    size="small"
                                    fullWidth
                                    required
                                    helperText={
                                        touched.reasonId && errors.reasonId
                                    }
                                    error={touched.reasonId && errors.reasonId}
                                >
                                    {reasonList.map((items, idx) => {
                                        return (
                                            <MenuItem
                                                key={items.reasonId}
                                                value={items.reasonId}
                                                data-key={items.reasonId}
                                            >
                                                {items.reasonName}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Stack>}
                            {/*file upload*/}
                            {showIndInformation && <Stack width="100%" display="flex">
                                <input
                                    id="fileUpload"
                                    type="file"
                                    accept=".pdf,.png,.jpg,.jpeg"
                                    multiple
                                    hidden
                                    onChange={handleFileEvent}
                                    disabled={fileLimit}
                                />

                                <Box
                                    padding={1}
                                    style={{
                                        borderRadius: "3px",
                                        textAlign: "center",
                                        border:
                                            showErrorFile || errors.proofDocuments
                                                ? "0.5px solid red"
                                                : "0.5px solid #ccc",
                                        background:
                                            showErrorFile || errors.proofDocuments
                                                ? "#ffe1e1"
                                                : "",
                                    }}
                                >
                                    <label htmlFor="fileUpload">
                                        {/*cloud upload icon*/}
                                        <BackupOutlinedIcon
                                            style={{fontSize: 40}}
                                        />
                                        <Typography align="center" variant="body2">
                                            Accept <strong> png, jpg, and pdf</strong>{" "}
                                            files only. The file size must be less
                                            than <strong>2 MB</strong> for each file.
                                            You can submit up to a maximum of{" "}
                                            <strong>5 </strong>
                                            files.
                                        </Typography>
                                        <Typography align="center" variant="body2">
                                            Please ensure that the file names are
                                            appropriate and relevant.
                                        </Typography>
                                        {/*browse files*/}
                                        <Box
                                            marginBottom={1}
                                            disabled={fileLimit}
                                            className={`btn btn-sm`}
                                        >
                                            <FolderIcon/> Browse files
                                        </Box>
                                    </label>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: {
                                                xs: "column",
                                                md: "row",
                                            },
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {uploadedFiles.map((file, idx) => (
                                            <Box
                                                key={idx}
                                                gap={1}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    border: "1px dashed #ccc",
                                                    borderRadius: "4px",
                                                    marginBottom: {xs: 1, md: 0.5},
                                                    marginLeft: {xs: 0, md: 0.5},
                                                    transition: "background-color 0.3s",
                                                    backgroundColor: "transparent",
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        backgroundColor: "#f4f6fc",
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        p: 1,
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{textAlign: "left"}}
                                                    >
                                                        {common.getFileIcon(file.name)} {file.name}{" "}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: "text.secondary",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                            <span
                                                style={{
                                                    flex: 1,
                                                    textAlign: "left",
                                                }}
                                            >
                                              {/* {file.fileSize} */}
                                                {file.size.toString().length <= 6
                                                    ? (file.size / 1024).toFixed(2)
                                                    : (
                                                        file.size /
                                                        1024 /
                                                        1024
                                                    ).toFixed(2)}
                                                {file.size.toString().length <= 6
                                                    ? "KB"
                                                    : "MB"}
                                            </span>
                                                        <VisibilityOutlinedIcon
                                                            style={{color: "#08bce0"}}
                                                            onClick={() => previewFile(file)}
                                                        />
                                                        <DeleteForeverOutlinedIcon
                                                            style={{color: "#b36161"}}
                                                            onClick={() =>
                                                                removeFile(file.name)(file)
                                                            }
                                                        />
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>

                                {showErrorFile && (
                                    <Typography color="error" variant="body2">
                                        {fileError}
                                    </Typography>
                                )}
                                {touched.proofDocuments &&
                                errors.proofDocuments && (
                                    <Typography color="error" variant="body2">
                                        {errors.proofDocuments}
                                    </Typography>
                                )}

                            </Stack>}
                             <Box
                                width="100%"
                                display="flex"
                                sx={{justifyContent: "center"}}
                                marginTop={1}
                                spacing={1}
                                gap={1}
                            >
                                <Button
                                    style={{textTransform: "initial"}}
                                    variant="outlined"
                                    color="warning"
                                    onClick={() => {
                                        setShowAddNewSpecialExemptionDialog(false);
                                    }}
                                >
                                    <Typography>Close</Typography>
                                </Button>
                                 {showIndInformation && <Button
                                    startIcon={<AddIcon/>}
                                    style={{textTransform: "initial"}}
                                    variant="outlined"
                                    disabled={progress}
                                    color="info"
                                    onClick={handleSubmit}
                                >
                                    <Typography>Add Now</Typography>
                                     {progress && (
                                         <CircularProgress
                                             size={30}
                                             sx={{
                                                 position: "absolute",
                                                 p: 1,
                                             }}
                                         />
                                     )}
                                </Button>}
                            </Box>

                        </DialogContent>
                    </Dialog>
                    {/*Remarks Dialog*/}
                    <Dialog
                        open={remarksDialog}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent>
                            {showDialogField && (
                                <Stack spacing={2}>
                                    <Typography variant="h6" align="center">
                                        <strong>{remarksStatus[status]}</strong>
                                    </Typography>

                                    {showPendingDraftStatus && (
                                        <TextField
                                            select
                                            required
                                            fullWidth
                                            size="small"
                                            label="Status"
                                            defaultValue={"N"}
                                            onChange={(e) => setStatus(e.target.value)}
                                        >
                                            <MenuItem value={pendingApproval}>
                                                Pending Approval
                                            </MenuItem>
                                            <MenuItem value={pendingRejection}>
                                                Pending Rejection
                                            </MenuItem>
                                        </TextField>
                                    )}
                                </Stack>
                            )}
                            <br/>
                            {status !== pendingApproval &&
                            status !== pendingRejection && status !== draftStatus && (
                                <TextareaAutosize
                                    minRows={3}
                                    style={{width: 550}}
                                    value={reviewRemarks}
                                    onChange={(e) => setReviewRemarks(e.target.value)}
                                />
                            )}
                            {(status === sendMail) && (
                                <ReactQuill
                                    value={textContent}
                                    onChange={handleEditorChange}
                                    placeholder="Type your content here..."
                                />
                            )}

                            {showAlert && (
                                <Collapse in={true}>
                                    <Alert severity="success">
                                        {status !== sendMail ? (
                                            <>
                                                {" "}
                                                Application has been{" "}
                                                {status === reviewStatus
                                                    ? "reviewed"
                                                    : status === approveStatus
                                                        ? "approved"
                                                        : status === rejectStatus
                                                            ? "rejected"
                                                            : status === draftStatus ||
                                                            status === pendingApproval ||
                                                            status === pendingRejection
                                                                ? "saved"
                                                                : "reverted"}{" "}
                                                successfully on {common.getCurrentDateFormat()}.
                                            </>
                                        ) : (
                                            <>
                                                Mail Send successfully.Please go to
                                                <Button
                                                    disableRipple
                                                    disableFocusRipple
                                                    onClick={(e) => {
                                                        navigate(
                                                            "/authenticatedViewWrapper/specialExemptionList",
                                                            {state: {}}
                                                        );
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            textDecoration: "underline",
                                                            color: "blue",
                                                            fontSize: "11px",
                                                        }}
                                                    >
                                                        special exemption list
                                                    </Typography>
                                                </Button>
                                                page to check the updated application.
                                            </>
                                        )}
                                    </Alert>
                                </Collapse>
                            )}
                        </DialogContent>
                        <DialogActions sx={{justifyContent: "center"}}>
                            {showDialogField && (
                                <Button
                                    variant="outlined"
                                    disabled={progress}
                                    color="warning"
                                    style={{textTransform: "initial"}}
                                    onClick={(e) => setRemarksDialog(false)}
                                >
                                    <Typography>Close</Typography>
                                </Button>
                            )}
                            {showDialogField && (
                                <Button
                                    autoFocus
                                    variant="outlined"
                                    disabled={progress}
                                    color="success"
                                    style={{textTransform: "initial"}}
                                    onClick={(e) => approvalBulkForm(e)}
                                >
                                    <Typography>{approvalStatusButton[status]}</Typography>
                                    {progress && (
                                        <CircularProgress
                                            size={30}
                                            sx={{
                                                position: "absolute",
                                                p: 1,
                                            }}
                                        />
                                    )}
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>
                    {/*Previous exemption files dialog*/}
                    <Dialog
                        open={popUpState}
                        onClose={() => {
                            setPopUpState(false);
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle>
                            <Typography align="center" variant="h5">
                                <strong>
                                    {fullNameApp} ({cidApp}) application(s)
                                </strong>
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            {previousExemptionList.map((row, index) => (
                                <>
                                    <Typography display="block" gutterBottom>
                                        <strong>Application Status:</strong>
                                        {nonBreakingSpace}
                                        <strong
                                            style={{
                                                color:
                                                    row.status === approveStatus ||
                                                    row.status === reviewStatus
                                                        ? "green"
                                                        : row.status === rejectStatus
                                                        ? "red"
                                                        : "orange",
                                            }}
                                        >
                                            {approvalStatus[row.status]}
                                        </strong>
                                    </Typography>

                                    <Typography display="block" gutterBottom>
                                        <strong>Year:</strong>
                                        {nonBreakingSpace}
                                        {row.exemptionYear}
                                    </Typography>

                                    <Typography display="block" gutterBottom>
                                        <strong>Reason:</strong>
                                        {nonBreakingSpace}
                                        {reasonList.find((r) => r.reasonId === row.reasonId)?.reasonName}
                                    </Typography>


                                    {row.exemptionFileDtos !== null
                                        ? row.exemptionFileDtos.map((file) => (
                                            <Typography display="block" gutterBottom>
                                                <Link
                                                    to="#"
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => common.downloadDocument(file)}
                                                >
                                                    {file.fileName}
                                                </Link>
                                            </Typography>
                                        ))
                                        : ""}
                                    <Divider
                                        variant="fullWidth"
                                        sx={{
                                            borderColor: "#a59c9c",
                                        }}
                                    />
                                </>
                            ))}
                        </DialogContent>
                        <DialogActions sx={{justifyContent: "center"}}>
                            <Button
                                variant="contained"
                                size="small"
                                color="error"
                                onClick={() => {
                                    setPopUpState(false);
                                }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                </>
            }
        />
    );
};

export default SpecialExemptionList;
