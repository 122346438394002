import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class CommonService {
    checkMyRegistration = () => {
        return axios.get(
            BASE_URL +
            "api/enrolment/deferment/exemption/edeCommon/checkMyRegistration",
            {
                headers: authHeader(),
            }
        );
    };

    checkMyRegistrationDeferment = () => {
        return axios.get(
            BASE_URL +
            "api/enrolment/deferment/exemption/edeCommon/checkMyRegistrationDeferment",
            {
                headers: authHeader(),
            }
        );
    };

    checkMyRegistrationExemption = () => {
        return axios.get(
            BASE_URL +
            "api/enrolment/deferment/exemption/edeCommon/checkMyRegistrationExemption",
            {
                headers: authHeader(),
            }
        );
    };


    getGraduateCategory = () => {
        return axios.get(
            BASE_URL +
            "api/enrolment/deferment/exemption/edeCommon/getGraduateCategory",
            {
                headers: authHeader(),
            }
        );
    };

    getQualificationCategory = () => {
        return axios.get(
            BASE_URL +
            "api/enrolment/deferment/exemption/edeCommon/getQualificationCategory",
            {
                headers: authHeader(),
            }
        );
    };

    getSkillCategory = () => {
        return axios.get(
            BASE_URL + "api/enrolment/deferment/exemption/edeCommon/getSkillCategory",
            {
                headers: authHeader(),
            }
        );
    };

    getFoodCategory = () => {
        return axios.get(
            BASE_URL + "api/enrolment/deferment/exemption/edeCommon/getFoodCategory",
            {
                headers: authHeader(),
            }
        );
    };
    getActiveEnlistmentYear = () => {
        return axios.get(
            BASE_URL +
            "api/enrolment/deferment/exemption/edeCommon/getActiveEnlistmentYear",
            {
                headers: authHeader(),
            }
        );
    };
    getActiveCancellationReason = () => {
        return axios.get(
            BASE_URL + "api/enrolment/deferment/exemption/edeCommon/getActiveCancellationReason",
            {
                headers: authHeader(),
            }
        );
    };

    getActiveSpecialExemptionReason = () => {
        return axios.get(
            BASE_URL + "api/enrolment/deferment/exemption/edeCommon/getActiveSpecialExemptionReason",
            {
                headers: authHeader(),
            }
        );
    };

    getDefermentExemptionByCid = (cid) => {
        return axios.get(
            BASE_URL + "api/enrolment/deferment/exemption/edeCommon/getDefermentExemptionByCid",
            {
                params: {cid},
                headers: authHeader(),
            }
        );
    };

    validationForMedical = (userId, year) => {
        return axios.get(
            BASE_URL + "api/enrolment/deferment/exemption/edeCommon/validationForMedical",
            {
                params: {userId, year},
                headers: authHeader(),
            }
        );
    };

    getActiveNsRegistrationCutOffDate = (userId, year) => {
        return axios.get(
            BASE_URL + "api/training/management/nsRegistrationCutOffDate/getActiveNsRegistrationCutOffDate",
            {
                headers: authHeader(),
            }
        );
    };

    getPreferences = () => {
        return axios.get(
            BASE_URL +
            "api/enrolment/deferment/exemption/edeCommon/getPreferences",
            {
                headers: authHeader(),
            }
        );
    };

    getTransportModes = () => {
        return axios.get(
            BASE_URL +
            "api/enrolment/deferment/exemption/edeCommon/getTransportModes",
            {
                headers: authHeader(),
            }
        );
    };

    getCohorts = () => {
        return axios.get(
            BASE_URL +
            "api/enrolment/deferment/exemption/edeCommon/getCohorts",
            {
                headers: authHeader(),
            }
        );
    };

    geLatestRegistrationDetails = (userId) => {
        return axios.get(
            BASE_URL + "api/enrolment/deferment/exemption/edeCommon/geLatestRegistrationDetails",
            {
                params: {userId},
                headers: authHeader(),
            }
        );
    };

    checkAgeValidation = (userId) => {
        return axios.get(
            BASE_URL + "api/enrolment/deferment/exemption/edeCommon/checkAgeValidation",
            {
                params: {userId},
                headers: authHeader(),
            }
        );
    };

    getAcademyList = () => {
        return axios.get(
            BASE_URL +
            "api/enrolment/deferment/exemption/edeCommon/getAcademyList",
            {
                headers: authHeader(),
            }
        );
    };

    checkEnlistmentNotice = (cid) => {
        return axios.get(
            BASE_URL +
            "api/enrolment/deferment/exemption/edeCommon/checkEnlistmentNotice",
            {
                params: {cid},
                headers: authHeader(),
            }
        );
    };

}

export default new CommonService();
